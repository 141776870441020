<template>
  <v-dialog
      v-model="isVisible"
      width="600"
      scrollable
  >
    <v-card>
      <v-card-title>New {{name}}</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 280px;">
        <v-container>
          <v-row v-if="showError">
            <v-col>
              <v-alert
                  type="error"
                  variant="outlined"
              >
                {{message}}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
            >
              <v-text-field
                  label="Id"
                  required
                  v-model="v$.model.id.$model"
                  :error="v$.model.id.$error"
                  :error-messages="idErrors"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
            >
              <v-text-field
                  label="Name"
                  required
                  v-model="v$.model.name.$model"
                  :error="v$.model.name.$error"
                  :error-messages="nameErrors"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex flex-row-reverse">
        <v-btn
            color="blue-darken-1"
            text
            @click="save()"
            :loading="isSaving"
        >
          Save
        </v-btn>
        <v-btn
            color="blue-darken-1"
            text
            @click="close()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {email, minLength, required, sameAs} from '@vuelidate/validators'
import userService from "@/services/user.service";
import catalogsService from "@/services/catalogs.service";


export default {
  name: "CatalogsNewDialog",
  props:{
    dialog: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      model: {
        id: { required },
        name: { required },
      },
    }
  },
  data() {
    return {
      title: "New Catalog",
      isSaving: false,
      showError: false,
      message: "",
      model:{
        id: "",
        name: "",
      }
    };
  },
  computed:{
    isVisible(){
      return this.dialog
    },
    idErrors(){
      return this.v$.model.id.$errors.map(err => err.$message);
    },
    nameErrors(){
      return this.v$.model.name.$errors.map(err => err.$message);
    },
  },
  watch:{
    dialog(val){
      if(val){
        this.$emit("on-open", val);
      }else {
        this.clearForm();
        this.$emit("on-close", val);
      }
    },
    "model.roleNames"(val){
      console.log(val)
    }
  },
  mounted() {
  },
  methods:{
    close(){
      this.$emit("on-close", false);
    },
    clearForm(){
      this.showError = false;
      this.model = {
        id: "",
        name: "",
      }
    },
    saveHighway(data){
      return catalogsService.createHighway(data)
    },
    saveLane(data){
      return catalogsService.createLane(data)
    },
    saveDirection(data){
      return catalogsService.createDirection(data)
    },
    saveSide(data){
      return catalogsService.createSide(data)
    },
    save(){
      this.message = "";
      this.showError = false;

      this.v$.$validate().then(isValid =>{
        if(isValid){
          this.isSaving = true;
          
          const data = {
            id: this.model.id,
            name: this.model.name
          }
          
          switch (this.name){
            case "Highway":
              return this.saveHighway(data);
            case "Lane":
              return this.saveLane(data);
            case "Direction":
              return this.saveDirection(data);
            case "Side":
              return this.saveSide(data);
          }
        }
      }).then(d =>{
        if(d) {
          this.clearForm();
          this.$emit("on-success", false);
        }
      }).catch(d =>{
        if(d.error){
          this.message = d.error.message;
          this.showError = true;
        }
      }).finally(() =>{
        this.isSaving = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
