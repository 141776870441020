<template>
  <div>
    <v-container class="py-6" fluid>
      <v-card class="card-shadow border-radius-xl">
<!--        <v-row>-->
<!--          <v-col cols="12" class="d-flex justify-center bg-surface-variant">-->
<!--            <v-btn variant="tonal" class="ma-2" @click="selectCatalog('Highway')">Highway</v-btn>-->
<!--            <v-btn variant="tonal" class="ma-2" @click="selectCatalog('Lane')">Lane</v-btn>-->
<!--            <v-btn variant="tonal" class="ma-2" @click="selectCatalog('Direction')">Direction</v-btn>-->
<!--            <v-btn variant="tonal" class="ma-2" @click="selectCatalog('Side')">Side</v-btn>-->
<!--          </v-col>-->
<!--        </v-row>-->

        <v-tabs 
            v-model="indexTab"
            dark
            background-color="grey darken-3"
            color="blue lighten-1">
          <v-tab value="Highway">Autopista</v-tab>
          <v-tab value="Lane">Sentido</v-tab>
          <v-tab value="Direction">Sentido</v-tab>
          <v-tab value="Side">Cuerpo</v-tab>
        </v-tabs>
        
        <v-row no-gutters>
          <v-col cols="12">
            <catalogs-table
                :title="selectedTab"
                :headers="headers"
                :items="items"
                :total-items="itemsCount"
                :page="page"
                :page-count="pageCount"
                :show-expand="showExpand"
                :is-loading="isLoading"
                @on-load="onLoad"
                @on-items-per-page="onItemsPerPage"
                @on-delete="onDelete"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import CatalogsTable from "./Widgets/CatalogsTable";
import catalogsService from "@/services/catalogs.service";

export default {
  name: "Catalogs",
  components: {
    CatalogsTable
  },
  data: () => ({
    options:["Highway","Lane","Direction","Side"],
    selectedTab: "Highway",
    indexTab: 0,
    headers: [
      {
        text: "Id",
        align: "start",
        value: "id",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: "Nombre",
        value: "name",
        class: "text-secondary font-weight-bolder opacity-7",
      },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    items: [],
    itemsCount: 0,
    page: 1,
    pageCount: 1,
    itemsPerPage: 5,
    showExpand: true,
    isLoading: false,
  }),
  watch: {
    indexTab(value) {
      if(value >= 0) {
        this.selectCatalog(this.options[value])
      }
    }
  },
  mounted() {
  },
  methods: {
    selectCatalog(catalog) {
      this.showExpand = catalog === "Highway";
      this.page = 1;
      this.pageCount = 1;
      this.selectedTab = catalog;
      
      this.loadCatalog();
    },
    onDelete(item){
      const catalog = this.selectedTab;
      
      switch (catalog) {
        case "Highway": {
          catalogsService.deleteHighway(item.id).then(_d => {
            this.$swal(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
            this.loadCatalog()
          });
        }
          break;
        case "Lane": {
          catalogsService.deleteLane(item.id).then(_d => {
            this.$swal(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
            this.loadCatalog()
          });
        }
          break;
        case "Direction": {
          catalogsService.deleteDirection(item.id).then(_d => {
            this.$swal(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
            this.loadCatalog()
          });
        }
          break;
        case "Side": {
          catalogsService.getSide(item.id).then(_d => {
            this.$swal(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
            this.loadCatalog()
          });
        }
          break;
      }
    },
    onLoad(data) {
      this.itemsPerPage = data.itemsPerPage;
      this.page = data.page;
      this.loadCatalog();
    },
    onItemsPerPage(val) {
      this.itemsPerPage = val
      this.loadCatalog()
    },
    loadCatalog() {
      const catalog = this.selectedTab;
      const offset = (this.page - 1) * this.itemsPerPage;
      
      this.isLoading = true;

      switch (catalog) {
        case "Highway": {
          catalogsService.getHigways(offset, this.itemsPerPage).then(d => {
            this.pageCount = Math.ceil(d.totalCount / this.itemsPerPage);
            this.items = d.items;
            this.itemsCount = d.totalCount;
            this.isLoading = false;
          })
        }
          break;
        case "Lane": {
          catalogsService.getLanes(offset, this.itemsPerPage).then(d => {
            this.pageCount = Math.ceil(d.totalCount / this.itemsPerPage);
            this.items = d.items;
            this.itemsCount = d.totalCount;
            this.isLoading = false;
          })
        }
          break;
        case "Direction": {
          catalogsService.getDirections(offset, this.itemsPerPage).then(d => {
            this.pageCount = Math.ceil(d.totalCount / this.itemsPerPage);
            this.items = d.items;
            this.itemsCount = d.totalCount;
            this.isLoading = false;
          })
        }
          break;
        case "Side": {
          catalogsService.getSide(offset, this.itemsPerPage).then(d => {
            this.pageCount = Math.ceil(d.totalCount / this.itemsPerPage);
            this.items = d.items;
            this.itemsCount = d.totalCount;
            this.isLoading = false;
          })
        }
          break;
      }
    }
  }
}
</script>

<style scoped>

</style>
