<template>

  <v-row>
    <v-col cols="12">
      <v-list
          flat
          subheader
      >
        <v-subheader class="d-flex">
          <v-select 
              v-model="selectedRelation"
              placeholder="Relations" 
              persistent-placeholder 
              :items="['Lane', 'Direction', 'Side']" />

          <v-btn class="ml-6 pl-6 pr-6" small :loading="isSaving" @click="save()">
            <v-icon left class="material-icons-round me-sm-1" size="20">
              save
            </v-icon>
            Save
          </v-btn>
        </v-subheader>

        <v-list-item-group
            v-model="itemsSelected"
            multiple
            active-class=""
        >
          <v-list-item v-for="item in items" :key="item.id" :value="item.id">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{item.name}}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>       
        
      </v-list>
    </v-col>
  </v-row>

</template>

<script>
import catalogsService from "@/services/catalogs.service";

export default {
  name: "CatalogsExpandedList",
  props:{
    highwayId:{
      type: Number,
      default: 0
    }
  },
  data:() => ({
    selectedRelation: "Lane",
    items: [],
    itemsSelected: [],
    isSaving: false
  }),
  watch:{
    selectedRelation(val){
      this.loadCatalogItems()
    },
    itemsSelected(val){
      console.log(val)
    }
  },
  mounted() {
    this.loadCatalogItems()
  },
  methods:{
    loadCatalogItems(){
      switch (this.selectedRelation){
        case "Lane":{
          catalogsService.getLanes(0,100).then(d =>{
            this.items = d.items            
            return catalogsService.getLanesByHighway(this.highwayId)
          }).then(d => {
            this.itemsSelected = d.items.map(i => i.id);
          })
        } break;
        case "Direction":{
          catalogsService.getDirections(0,100).then(d =>{
            this.items = d.items
            return catalogsService.getDirectionsByHighway(this.highwayId)
          }).then(d =>{
            this.itemsSelected = d.items.map(i => i.id);
          })
        } break;
        case "Side":{
          catalogsService.getSide(0,100).then(d =>{
            this.items = d.items
            return catalogsService.getSidesByHighway(this.highwayId)
          }).then(d =>{
            this.itemsSelected = d.items.map(i => i.id);
          })
        } break;
      }
    },
    save(){
      const data = {
        highwayId: this.highwayId,
      };
      
      switch (this.selectedRelation){
        case "Lane":{
          data["lanes"] = this.itemsSelected.map(i => i)
        } break;
        case "Direction":{
          data["directions"] = this.itemsSelected.map(i => i)
        } break;
        case "Side":{
          data["sides"] = this.itemsSelected.map(i => i)
        } break;
      }
      
      this.isSaving = true;
      
      catalogsService.saveHighwayRelations(data).then(d => {
        this.loadCatalogItems()
      }).finally(() =>{
        this.isSaving = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
