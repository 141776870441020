<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <catalogs-new-dialog :name="title" :dialog="showDialog" @on-close="showDialog=false" @on-success="onSuccess" />
    <v-card class="border-radius-xl">
      <v-card-text class="px-0 py-0">
        <v-data-table
            :loading="isLoading"
            :options.sync="options"
            :headers="headers"
            :items="items"
            :items-per-page="itemsPerPage"
            :server-items-length="totalItems"
            :page.sync="page"
            :search="search"
            class="table border-radius-xl"
            hide-default-footer
            mobile-breakpoint="0"
            :show-expand="showExpand"
        >
          <template v-slot:top>
            <v-toolbar color="grey darken-3" flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                      v-model="search"
                      class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                      dense
                      filled
                      flat
                      height="43"
                      hide-details
                      placeholder="Buscar..."
                      solo
                  >
                    <template slot="prepend-inner">
                      <v-icon
                          class="material-icons-round"
                          color="#adb5bd"
                          size="20px"
                      >search
                      </v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-btn class="float-right" @click="showDialog=true">
                    Nuevo {{namedTitle}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <catalogs-expanded-list :highway-id="item.id" />
            </td>
          </template>
<!-- eslint-disable vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="material-icons-round mr-2"
                @click="editItem(item)"
            >
              edit
            </v-icon>
            <v-icon
                small
                class="material-icons-round"
                @click="deleteItem(item)"
            >
              delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col class="d-flex align-center" cols="6" lg="3">
            <span class="text-body me-3 mb-5 text-sm">Elementos por página:</span>
            <v-select
                v-model="itemsPerPage"
                dense
                filled
                solo
                :items="[5,10,20,50]"
            ></v-select>
          </v-col>
          <v-col class="ml-auto d-flex justify-end" cols="6">
            <v-pagination
                v-model="page"
                :length="pageCount"
                circle
                class="pagination"
                color="#d81b60"
                next-icon="fa fa-angle-right"
                prev-icon="fa fa-angle-left"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import CatalogsExpandedList from "./CatalogsExpandedList";
import CatalogsNewDialog from "./CatalogsNewDialog";
// eslint-disable-next-line no-unused-vars
import catalogsService from "@/services/catalogs.service";

export default {
  name: "CatalogsTable",
  components: {
    CatalogsExpandedList,
    CatalogsNewDialog
  },
  props:{
    title:{
      type: String,
      default: ""
    },
    search:{
      type: String,
      default: ""
    },
    items:{
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    totalItems:{
      type: Number,
      default: 0
    },
    headers: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    page:{
      type: Number,
      default: 1
    },
    pageCount:{
      type: Number,
      default: 1
    },   
    showExpand:{
      type:Boolean,
      default: false
    },
    isLoading:{
      type:Boolean,
      default: false
    }
  },
  data: () => ({
    itemsPerPage: 10,
    options: {},
    showDialog: false,
  }),
  computed:{
    // eslint-disable-next-line vue/return-in-computed-property
    namedTitle(){
      switch (this.title){
        case 'Highway': return 'Autopista';
        case 'Lane': return 'Carril';
        case 'Direction': return 'Sentido';
        case 'Side': return 'Cuerpo';
      }
    }
  },
  mounted() {
  },
  watch:{
    // eslint-disable-next-line no-unused-vars
    itemsPerPage(val){
      this.$emit("on-load", {
        itemsPerPage: this.itemsPerPage,
        page: this.page,
      });
    },
    search(val){
      this.$emit("on-search", val);
    },
    options() {
      this.$emit("on-load", {
        itemsPerPage: this.itemsPerPage,
        page: this.page,
      });
    }
  },
  methods:{
    // eslint-disable-next-line no-unused-vars
    onSuccess(data){
      this.showDialog = false;
      this.$swal(`${this.title} created`,"","success").then(() =>{
        this.$emit("on-load", {
          itemsPerPage: this.itemsPerPage,
          page: this.page,
        });
      });
    },
    deleteItem(item){
      this.$swal({
        title: `Seguro que deseas eliminar [${item.id}] ${this.namedTitle}?`,
        text: "No podras revertir esta acción!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit("on-delete", item);
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
